<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "旺幣任務設定",
      items: [
        {
          text: "用戶等級任務管理",
          href: "/usermanagement",
        },
        {
          text: "旺幣任務設定",
          active: true,
        }
      ],
      searchData:{
        questType: 1,
        status: 0,
        startDate: null,
        endDate: null
      },
      selectedStartDate: null,
      selectedEndDate: null,
      value:'',
      daterange:'',
      visible: true,
      options: [
        "Alaska",
        "Hawaii",
        "California",
        "Nevada",
        "Oregon",
        "Washington",
        "Arizona",
        "Colorado",
        "Idaho",
        "Montana",
        "Nebraska",
        "New Mexico",
        "North Dakota",
        "Utah",
        "Wyoming",
        "Alabama",
        "Arkansas",
        "Illinois",
        "Iowa",
      ],
      statusOptions: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '上架'
        },
        {
          id: 2,
          name: '下架'
        }
      ],


      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields1: [
        {
          key: "name",
          label: "任務名稱",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "wancoin",
          label: "可獲得旺幣數量",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "status",
          label: "狀態",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "action",
          label: "",
          sortable: false,
          stickyColumn: true,
          class: 'width-auto center'
        }
      ],
      fields2: [
        {
          key: "name",
          label: "任務名稱",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "wancoin",
          label: "可獲得旺幣數量",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "start_date",
          label: "開始時間",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "end_date",
          label: "截止時間",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "status",
          label: "狀態",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "action",
          label: "操作",
          sortable: false,
          stickyColumn: true,
          class: 'width-auto center'
        }
      ],
      tab: 1,
      date:''
    };
  },
  computed:{
    rows() {
      return this.tableData.length;
    },
    useField() {
      switch (this.tab) {
        case 1:
          return this.fields1
        case 2:
        case 3:
        case 4:
          return this.fields2
      }
    }
  },
  mounted() {
    this.reloadSearchList()
  },
  methods: {
    reloadSearchList () {
      this.$refs.searchTable.refresh()
    },
    searchList (_table, _callback) {
      const vm = this

      const searchParam = {
        page: this.currentPage || 1,
        quest_type: this.searchData.questType || 1,
        status: this.searchData.status || 0,
        start_date: this.searchData.startDate || null,
        end_data: this.searchData.endDate || null
      }
      this.$root.apis.getWancoinQuests(searchParam, 
        function (_response) {
          let response = _response.body.data
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          if (typeof _callback === 'function') {
            vm.tableData = response.items
            _callback(vm.tableData)
          }
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeTab(_status){
      if (this.tab !== _status) {
        this.tab = _status
        this.searchData.questType = _status
        this.reloadSearchList()
      }
    },
    onDateSelect(_date, _range) {
      _range === 'startDate' ? this.selectedStartDate = _date : this.selectedEndDate = _date
      this.searchData[_range] = this.$root.common.getNowTime('date', _date)
    },
    onSelected(_options) {
      this.searchData.status = _options.id
    },
    editQuest(_questId) {
      this.$router.push({ path: `/wanciontask/setting/${_questId}`})
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box.row
      .col-lg-9
        b-button-group.tab-group
          b-button.mr-2(variant="primary" :class="{off: tab !== 1}" @click="changeTab(1)") 常態旺幣任務
          b-button.mr-2(variant="primary" :class="{off: tab !== 2}" @click="changeTab(2)") 活動旺幣任務
          b-button.mr-2(variant="primary" :class="{off: tab !== 3}" @click="changeTab(3)") 待上架旺幣任務
          b-button.mr-2(variant="primary" :class="{off: tab !== 4}" @click="changeTab(4)") 已下架旺幣任務
      .col-lg-3.d-flex.justify-content-end.align-items-center
        b-button.width-md(variant="primary" to="/wanciontask/setting") 新增旺幣任務
    .col-lg-12
      //- 搜尋bar
      b-card.col-lg-12.mb-1(no-body)
        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group
                  label 狀態
                  multiselect(
                    :value="statusOptions.find(_status => _status.id === searchData.status)",
                    @input="onSelected"
                    :options="statusOptions", 
                    deselect-label="Can't remove this value"
                    :allow-empty="false",
                    placeholder="請選擇狀態", 
                    track-by="id"
                    label="name"
                    select-label=""
                  )
              .col-lg-4  
                .form-group
                  label 開始時間
                  date-picker(
                    :value="selectedStartDate",
                    @input="(_value) => onDateSelect(_value, 'startDate')"
                    @clear="searchData.startDate = null"
                    append-to-body,
                    lang="en",
                    confirm,
                    format="YYYY-MM-DD",
                    placeholder="請選擇日期區間"
                  )
              .col-lg-4  
                .form-group
                  label 結束時間
                  date-picker(
                    :value="selectedEndDate"
                    @input="(_value) => onDateSelect(_value, 'endDate')"
                    @clear="searchData.endDate = null"
                    append-to-body,
                    lang="en",
                    confirm,
                    format="YYYY-MM-DD",
                    placeholder="請選擇日期區間"
                  )
            .row
              .col.mt-1.mr-3.d-flex.justify-content-end.p-0
                b-button.width-md(variant="primary" @click="reloadSearchList") 查詢
      //- 表格
      .card
        .card-body
          .header-row.mb-3
            .header-title(v-if="tab === 1") 常態旺幣任務列表
            .header-title(v-if="tab === 2") 活動旺幣任務
            .header-title(v-if="tab === 3") 待上架旺幣任務
            .header-title(v-if="tab === 4") 已下架旺幣任務
          //- Table
          .row
            .col-12
              b-table(
                head-variant="light"
                ref="searchTable",
                :items="searchList",
                :fields="useField",
                responsive,
                :per-page="perPage",
                :current-page="currentPage",
                :sort-desc.sync="sortDesc",
                :filter="filter",
                :filter-included-fields="filterOn",
                @filtered="onFiltered")

                template(v-slot:cell(status)="data")
                  b-button(
                    v-if="data.item.status === 0", 
                    variant="danger", 
                    size="sm"
                  ) 下架
                  b-button(
                    v-else-if="data.item.status === 1",
                    variant="success",
                    size="sm"
                  ) 上架
                  b-button(
                    v-else-if="data.item.status === 2", 
                    variant="success", 
                    size="sm"
                  ) 待上架
                  span(v-else) -

                template(v-slot:cell(action)="data")
                  b-button.width-lg(variant="primary" @click="editQuest(data.item.id)") 編輯

          .row.mb-md-2
            .col-12.row.d-flex.justify-content-end  
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
      //- 表格二
      .card(style="display: none")
        .card-body.col-lg-12
          .header-row
            .form-group.col-lg-9.pl-3.row.mb-3
              .form-group.col-lg-5.pl-0.mb-0
                multiselect(v-model="value", :options="options", select-label="",placeholder="建立時間(新-舊)",  :multiple="true")
              .pl-2.mt-2.mb-0
                b-form-checkbox#checkbox-1(v-model="statusOptions", name="checkbox", value="accepted", unchecked-value="not_accepted") 總統大選
              .pl-2.mt-2.mb-0
                b-form-checkbox#checkbox-1(v-model="statusOptions", name="checkbox", value="accepted", unchecked-value="not_accepted") 九合一選舉
            .col-lg-3.mt-0.mb-4.row.d-flex.justify-content-end  
                b-button.tipsbtn.width-lg(variant="primary") ＋　新增小知識
          //- Table
          .table-responsive.mb-0
            b-table(:items="tableData", :fields="fields1", responsive="sm", :per-page="perPage", :current-page="currentPage", :sort-desc.sync="sortDesc", :filter="filter", :filter-included-fields="filterOn", @filtered="onFiltered")
          .row.mb-md-2
            //- .col-6
            //-   div(id="tickets-table_length", class="dataTables_length")
            //-     label.d-inline-flex.align-items-center
            //-       | Show
            //-       b-form-select.ml-1.mr-1(v-model="perPage", size="sm", :options="pageOptions")
            //-       | entries
            .col-12.row.d-flex.justify-content-end  
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="rows", :per-page="perPage")
</template>
<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
.header-row
  display: flex
  justify-content: space-between
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  // background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
  background: #f1f5f7

::v-deep td
  &.b-table-sticky-column:last-child
    background: #fff !important
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
</style>
